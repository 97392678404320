<template>
  <div class="main-page">
    <!--    @action="cancelOrder"-->
    <div class="widget-bg">
      <page-header :title="$route.meta.title"></page-header>
      <component-position :columns="columnDefs" :detailRows="detailRows" :positions="localOrders" @action="selectPosition" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import ComponentPosition from '@/components/mobile/ComponentPosition.vue';
import PageHeader from '@/components/mobile/ComponentPageHeader.vue';

export default {
  name: 'MobileOrders',
  components: {
    PageHeader,
    ComponentPosition,
  },
  data() {
    return {
      activeIndex: null,
      localOrders: [],
      columnDefs: [
        { field: 'cancel', class: ['cancel'], title: '', type: 'string' },
        { field: 'direction', class: ['direction'], title: 'direction', type: 'string', colored: true },
        { field: 'symbol', class: ['symbol'], title: 'instrument', type: 'string', search: 'text' },
        { field: 'quantity', class: ['quantity'], title: 'кол-во', type: 'number', colored: true },
        { field: 'status', class: ['status'], title: 'статус', type: 'text' },
      ],
      detailRows: [
        { field: 'account', title: 'Cчет', type: 'text' },
        { field: 'type', title: 'Тип', type: 'text' },
        { field: 'time', title: 'Выставлено', type: 'datetime' },
        { field: 'price', title: 'Цена', type: 'composite' },
        { field: 'filled', title: 'Исполнено', type: 'number' },
        { field: 'tif', title: 'Срок', type: 'text' },
        { field: 'transaction_id', title: 'Номер приказа', type: 'text' },
        { field: 'symbol', title: 'buy/sell', type: 'button' },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getOrders: 'getOrders',
      orderStatus: 'getOrderStatus',
      orderType: 'getOrderType',
    }),
  },
  methods: {
    ...mapMutations({
      setDefaultOrder: 'setDefaultOrder',
      changeOrder: 'changeOrder',
      changeModals: 'changeModals',
    }),
    ...mapActions({
      cancelOrder: 'cancelOrder',
      updateSelectedSymbol: 'updateSelectedSymbol',
    }),
    getSymbol(symbol) {
      return symbol;
    },
    getType(serverType) {
      return this.orderType[serverType]; //[0].toUpperCase();
    },
    getStatus(serverStatus) {
      return this.orderStatus[serverStatus]; //[0].toUpperCase();
    },
    createLocalOrders(newState) {
      this.localOrders = [];
      newState.sort((a, b) => {
        return a.accepted_time < b.accepted_time ? 1 : a.accepted_time > b.accepted_time ? -1 : 0;
      });
      for (const each of newState) {
        const price = [];
        for (const p of ['stop', 'stop_price', 'price'])
          if (each[p] !== undefined)
            price.push({
              value: each[p],
              type: 'currency',
            });

        this.localOrders.push({
          key: each.id,
          direction: each.action,
          symbol: this.getSymbol(each.instrument.symbol),
          quantity: each.quantity,
          status: this.getStatus(each.status),
          account: each.account,
          type: this.getType(each.type),
          time: each.accepted_time,
          price,
          filled: each.filled,
          tif: each.tif,
          transaction_id: each.transaction_id,
          // stop: each.stop || null,
          // commission: each.commission,
          // time_extended: each.time_extended,
          data: each,
          deletedRow: each.canceled,
        });
      }
    },
    async selectPosition(key) {
      const order = this.localOrders.find((item) => item.key.toString() === key.toString());
      this.updateSelectedSymbol(order.data.instrument.symbol);
      const action = ['filled', 'part_filled'].includes(order.data.status) ? (order.action === 'buy' ? 'sell' : 'buy') : order.data.action;
      this.setDefaultOrder({});
      this.changeOrder({
        action,
        type: order.data.type,
        quantity: order.data.quantity,
        limitPrice: order.data.price || 0.0,
      });
      if (order.data.status === 'approval')
        return void this.changeModals({
          orderConfirm: {
            visible: true,
            data: order.data,
          },
        });
      this.$router.push({ name: 'order' });
    },
  },
  watch: {
    getOrders: {
      handler(newState) {
        this.createLocalOrders(newState);
      },
      deep: true,
    },
  },
  created() {},
  mounted() {
    this.createLocalOrders(this.getOrders);
  },
  unmounted() {},
};
</script>
<style lang="scss" scoped></style>
