<template>
  <div class="main-page">
    <div class="widget-bg">
      <page-header :title="$route.meta.title">
        <template #left></template>
        <template #right>
          <div class="add-instrument" @click="addInstrument">
            <img :src="icons.addWatch" alt="" />
          </div>
        </template>
      </page-header>

      <component-position
        :columns="columnDefs"
        :positions="localWatchList.filter((each) => each.data.name === selectedWl)"
        @action="selectPosition"
        @delete="deleteInstrumentWL"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { icons } from '@/assets/svg-img';
import PageHeader from '@/components/mobile/ComponentPageHeader.vue';
import ComponentPosition from '@/components/mobile/ComponentPosition.vue';

export default {
  name: 'MobileWatchList',
  components: {
    PageHeader,
    ComponentPosition,
  },
  data() {
    return {
      icons,
      columnDefs: [
        { field: 'symbol', class: ['symbol', 'action'], title: 'instrument', type: 'string', search: 'text' },
        { field: 'price', class: ['price'], title: 'цена', type: 'currency' },
        { field: 'change', class: ['colored'], title: 'изм. в $', type: 'currency', colored: true },
        { field: 'changeP', class: ['colored'], title: 'изм. в %', type: 'percent', colored: true },
        { field: 'delete', class: ['delete'], title: '', type: 'string' },
      ],
      localWatchList: [],
    };
  },
  computed: {
    ...mapGetters({
      watchList: 'getWatchList',
      selectedWl: 'getSelectedWl',
    }),
  },
  methods: {
    ...mapMutations({
      setDefaultOrder: 'setDefaultOrder',
    }),
    ...mapActions({
      updateSelectedSymbol: 'updateSelectedSymbol',
      deleteInstrumentWL: 'deleteInstrumentWL',
    }),
    addInstrument() {
      this.$router.push({ name: 'search.list' });
    },
    createLocalWatchList(newState) {
      this.localWatchList = [];
      for (const each of newState) {
        this.localWatchList.push({
          key: each.symbol,
          symbol: each.symbol,
          price: each.price,
          change: each.change,
          changeP: each.changeP,
          data: each,
          deletedRow: true,
        });
      }
    },
    selectPosition(key) {
      const wl = this.localWatchList.find((item) => item.key.toString() === key.toString());
      this.updateSelectedSymbol(wl.data.instrument.symbol);
      this.setDefaultOrder({});
      this.$router.push({ name: 'order' });
    },
  },
  watch: {
    watchList: {
      handler(newState) {
        this.createLocalWatchList(newState);
      },
      deep: true,
    },
  },
  created() {},
  mounted() {
    this.createLocalWatchList(this.watchList);
  },
  unmounted() {},
};
</script>

<style lang="scss" scoped>
.wrap {
  padding: 10px 15px 20px;

  .instrument {
    display: flex;
    padding: 8px 12px;
    border-radius: var(--main-border-radius);
    border: 1px solid var(--input-alt-default-border-outside);
    margin-bottom: 10px;
    box-shadow: 0 3px 6px var(--dropdown-alt-border-outside);
    background: rgba(24, 25, 27, 0.4);
    justify-content: space-between;
    align-items: center;

    .hint {
      color: var(--hint-color);
      font-size: 9px;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 3px;
    }

    .symbol {
      font-weight: bold;
      color: var(--white);
      width: 20%;
      margin-right: 2px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .delete {
    width: 15px;
    height: 10px;
  }
}

.add-instrument {
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
