<template>
  <div class="chart-headline">
    <div class="selected-symbol">{{ selectedSymbol }}</div>

    <div v-if="modals.orderForm.visible" class="orders-btn-wrap">
      <div
        v-for="action in actions"
        :key="action.value"
        :class="[action.value, { active: order.deal.orders[1].action === action.value }]"
        class="btn"
        @click.stop="handleClick(action.value, $event)"
      >
        {{ action.text }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import animation from '@/utils/animation';

export default {
  name: 'ChartHeadline',
  components: {},
  data() {
    return {
      actions: [
        { value: 'buy', text: 'Buy' },
        { value: 'sell', text: 'Sell' },
      ],
    };
  },
  computed: {
    ...mapGetters({
      selectedSymbol: 'getSelectedSymbol',
      order: 'getOrder',
      orders: 'getOrders',
      modals: 'getModals',
    }),
  },
  props: {},
  methods: {
    ...mapMutations({
      changeModals: 'changeModals',
      changeOrder: 'changeOrder',
    }),
    ...mapActions({}),
    openModalOrder() {
      this.changeModals({ orderForm: { visible: true, data: {} } });
    },
    handleClick(actionValue) {
      this.order.deal.orders[1].action = actionValue;
      this.openModalOrder();
      animation.pressing(event);
    },
  },
  watch: {},
  created() {},
  mounted() {},
  beforeUnmount() {},
};
</script>

<style lang="scss" scoped>
.chart-headline {
  padding: 0 10px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .selected-symbol {
    font-size: 14px;
    font-weight: 600;
  }

  .orders-btn-wrap {
    display: flex;
    align-items: center;
    gap: 0 4px;

    .btn {
      border-radius: var(--main-border-radius);
      border: solid 1px;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: 600;
      cursor: pointer;
      height: 20px;
      transition: all 0.3s ease-in-out;
    }

    .buy {
      border-color: rgba(0, 255, 0, 0.6);

      &.active {
        background: rgba(0, 255, 0, 0.6);
      }
    }

    .sell {
      border-color: rgba(255, 0, 0, 0.7);

      &.active {
        background: rgba(255, 0, 0, 0.7);
      }
    }
  }
}
</style>
