import utils from '@/utils';

const keyFloat = [
  'time_extended',
  'quantity',
  'filled',
  'price',
  'money',
  'commission',
  'average',
  'cost',
  'amount',
  'stop',
  'stop_price',
  'multiplier',
  'assets',
  'cashes',
  'bp',
];
const keyData = ['status_time', 'date_time', 'accepted_time'];

export default {
  makePortfolioByRow: function ({
    account,
    date_time,
    instrument,
    quantity = 0.0,
    price = 0.0,
    average = 0.0,
    position = 0.0,
    transactions = [],
  }) {
    const each = {
      account,
      date_time,
      instrument,
      quantity,
      price,
      // day: { quantity: 0.0, cash: 0.0, commission: 0.0, close: 0.0 },
      day: { quantity: 0.0, cash: 0.0, commission: 0.0 },
      transactions: {},
      pnl: { position, average, unrealized: 0.0, realized: 0.0 },
    };
    for (const transaction of transactions) each.transactions[transaction.transaction_id] = this.makeTransaction(transaction);
    return each;
  },
  makeTransaction: function ({ transaction_id, date_time, price, quantity, commission = 0.0, list }) {
    return { id: transaction_id, date_time, price, quantity, commission, realized: 0.0, list };
  },
  makeTradesByOrder: function (order, currentPrice) {
    return [
      {
        transaction_id: order.transaction_id,
        commission: 0.0,
        commission_currency: 'USD',
        date_time: order.status_time,
        quantity: order.filled,
        price: order.price || currentPrice,
        list: 'orders',
      },
    ];
  },
  // eslint-disable-next-line no-unused-vars
  getPrice: function (symbol, endTime) {
    if (symbol === undefined) return 0.0;
    return symbol.rtc || symbol.lp;
  },
  processingData: function (item) {
    if (Array.isArray(item)) {
      for (const each of item) this.processingData(each);
    } else {
      for (const key of Object.keys(item)) {
        if (keyData.includes(key)) {
          item[key] = new Date(item[key] * Math.pow(10, 13 - String(item[key]).length));
        } else if (keyFloat.includes(key)) {
          if (item[key] !== null) item[key] = parseFloat(item[key] || 0.0);
        } else if (['trades', 'purchase_details', 'transactions', 'total', 'instrument'].includes(key)) {
          this.processingData(item[key]);
        }
      }
    }
  },
  positionChange: function (position) {
    position.day.quantity = position.day.cash = position.day.commission = 0.0;
    position.pnl.average = position.pnl.position = position.pnl.realized = 0.0;
    const transactions = Object.keys(position.transactions)
      .map((key) => position.transactions[key])
      .sort((a, b) => (a.date_time > b.date_time ? 1 : a.date_time < b.date_time ? -1 : 0));
    for (const transaction of transactions) {
      const { date_time, quantity, price, commission } = transaction;

      if (transaction.list === 'corporate_actions') {
        const ratio = 1 + quantity / position.pnl.position;
        position.pnl.position = position.pnl.position * ratio;
        position.pnl.average = position.pnl.average / ratio;
      } else {
        if ((position.pnl.position >= 0 && quantity > 0) || (position.pnl.position <= 0 && quantity < 0)) {
          const newPosition = position.pnl.position + quantity;
          if (position.quantity + position.day.quantity === 0.0) {
            position.pnl.average = price;
          } else {
            position.pnl.average = utils.round(
              (position.pnl.average * Math.abs(position.pnl.position) + price * Math.abs(quantity)) / Math.abs(newPosition),
              5,
            );
          }
          position.pnl.position = newPosition;
        } else {
          if (Math.abs(quantity) <= Math.abs(position.quantity + position.day.quantity)) {
            position.pnl.realized += transaction.realized = -1 * quantity * position.instrument.multiplier * (price - position.pnl.average);
          } else {
            const newPosition = quantity + position.quantity;
            transaction.realized = -1 * position.quantity * position.instrument.multiplier * (price - position.pnl.average);
            position.pnl.realized = -1 * newPosition * position.instrument.multiplier * (price - position.pnl.average);
            position.pnl.average = price;
            position.pnl.position = newPosition;
          }
        }

        if (date_time > position.date_time) {
          position.day.quantity += quantity;
          position.day.cash += -1 * utils.round(quantity * price, 5);
          position.day.commission += commission;
        }
      }
    }
  },
  updatePortfolioPrice: function ({ portfolio, symbols }) {
    const leverage = 2;
    for (const key in portfolio.total) portfolio.total[key] = 0.0;
    for (const position of portfolio.items) {
      const quantity = position.quantity + position.day.quantity;
      if (position.instrument.asset_category === 'CASH') {
        position.price = ['USD'].includes(position.instrument.symbol)
          ? 1.0
          : position.price || this.getPrice(symbols[position.instrument.symbol]);
        portfolio.total.cashes += quantity * position.instrument.multiplier * position.price;
        portfolio.total.bp += quantity * position.instrument.multiplier * position.price;
      } else {
        if (position.instrument.listing_exchange !== 'Empty') position.price = this.getPrice(symbols[position.instrument.symbol]);
        position.pnl.unrealized =
          (quantity > 0 ? 1 : -1) * (position.price - position.pnl.average) * Math.abs(quantity) * position.instrument.multiplier;

        portfolio.total.assets += quantity * position.instrument.multiplier * position.price;
        portfolio.total.bp +=
          position.instrument.asset_category === 'STK' ? (quantity * position.instrument.multiplier * position.price) / leverage : 0.0;
      }
      portfolio.total.amount += quantity * position.instrument.multiplier * position.price;
    }
  },
  updateCash: function (portfolioItems) {
    const cashId = portfolioItems.findIndex((item) => item.instrument.symbol === 'USD');
    portfolioItems[cashId].day.quantity = portfolioItems[cashId].day.cash = 0.0;
    for (let key = 0; key < portfolioItems.length; key++) {
      if (key !== cashId) {
        portfolioItems[cashId].day.quantity += portfolioItems[key].day.cash;
        portfolioItems[cashId].day.cash += portfolioItems[key].day.cash;
      }
    }
  },
  portfolioBySymbol: function (portfolio, symbol) {
    for (const each of portfolio.items) {
      if (each.instrument && each.instrument.symbol === symbol) return each.quantity + each.day.quantity;
    }
    return 0;
  },
  getActiveOrders: function (symbol, orders) {
    return orders.filter((item) => ['approval', 'pending', 'accepted'].includes(item.status) && item.instrument.symbol === symbol);
  },
  isShort: function (instruction, portfolio, orders) {
    const newQuantity = (instruction.deal.orders[1].action === 'sell' ? -1 : 1) * Math.abs(instruction.deal.general.quantity);
    const portfolioQuantity = this.portfolioBySymbol(portfolio, instruction.deal.general.symbol);

    let ordersQuantity = 0;
    for (const item of this.getActiveOrders(instruction.deal.general.symbol, orders)) {
      ordersQuantity += item.quantity;
    }
    return portfolioQuantity + ordersQuantity + newQuantity < 0;
  },
  textSendOrder: function (each) {
    let text = 'Приказ успешно создан: ';
    text += each.full_name + ' ' + each.advice.symbol;
    text += each.advice.open > 0 ? ` цена: ` + each.advice.open : ' ' + each.advice.type;
    text += `, количество: ` + each.advice.quantity + `<br />`;
    return text;
  },
  orderSortText: function (instr) {
    let text = 'Ордер отправлен ' + instr.deal.general.symbol + ' @ ' + instr.deal.orders[1].type.toUpperCase();
    if (Number(instr.deal.orders[1].triggerPrice) > 0) text += ' ' + Number(instr.deal.orders[1].triggerPrice);
    if (Number(instr.deal.orders[1].limitPrice) > 0) text += ' ' + Number(instr.deal.orders[1].limitPrice);
    text += ' ' + instr.deal.orders[1].action.toUpperCase();
    const orders = [];
    for (const each of instr.list) {
      orders.push(each.account + ': ' + utils.formatValue(Number(each.quantity || instr.deal.general.quantity), 'number'));
    }
    text += ' (' + orders.join(', ') + ')';
    text += ' ' + instr.deal.orders[1].tif.toUpperCase();
    return text;
  },
};
